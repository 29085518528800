<template>
  <div class="duties">
    <h2>Dienste für Woche {{ currentWeek }}</h2>
    <div class="duty-container">
      <div class="duty-section">
        <div class="section-header">
          <i class="fas fa-basketball-ball"></i>
          <h3>Hallendienst</h3>
        </div>
        <ul>
          <li v-for="person in currentDuties.courtDuty" :key="person">{{ person }}</li>
        </ul>
      </div>
      <div class="duty-section">
        <div class="section-header">
          <i class="fas fa-money-bill-wave"></i>
          <h3>Kassendienst</h3>
        </div>
        <ul>
          <li v-for="person in currentDuties.cashDuty" :key="person">{{ person }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    duties: {
      type: Array,
      required: true
    }
  },
  computed: {
    currentWeek() {
      const currentDate = new Date();
      const start = new Date(currentDate.getFullYear(), 0, 1);
      const diff = currentDate - start;
      const oneWeek = 1000 * 60 * 60 * 24 * 7;
      return Math.ceil(diff / oneWeek);
    },
    currentDuties() {
      return this.duties.find(duty => duty.week === (this.currentWeek + 6) % this.duties.length);
    }
  }
};
</script>

<style scoped>
.duties {
  text-align: center;
}

.duty-container {
  display: flex;
  gap: 30px;
  justify-content: space-around;
  margin-top: 20px;
}

.duty-section {
  background-color: #ecf0f1;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 45%;
}

.section-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.section-header i {
  margin-right: 10px;
  font-size: 1.5em;
  color: #2980b9;
}

h3 {
  color: #16a085;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  background-color: #bdc3c7;
  margin: 5px 0;
  padding: 8px;
  border-radius: 4px;
}
</style>
