<template>
  <div id="app">
    <h1>Team Dienste</h1>
    <div class="container">
      <DutyDisplay :duties="duties" />
    </div>
  </div>
</template>

<script>
import DutyDisplay from './components/DutyDisplay.vue';

export default {
  name: 'App',
  components: {
    DutyDisplay
  },
  data() {
    return {
      duties: [
        { week: 1, courtDuty: ["Jason", "Tim", "Jacob"], cashDuty: ["Simon", "Max", "Dima"] },
          { week: 2, courtDuty: ["Niclas, Bruno"], cashDuty: ["Oleksandr", "Volodymyr", "Jonathan"] },
          { week: 3, courtDuty: ["Benedikt", "MJ"], cashDuty: ["Jason", "Tim", "Jacob"] },
          { week: 4, courtDuty: ["Ali", "Niklas"], cashDuty: ["Niclas", "Bruno"] },
          { week: 5, courtDuty: ["Daniel", "Stephan"], cashDuty: ["Benedikt", "MJ"] },
          { week: 6, courtDuty: ["Kieran", "Marcel"], cashDuty: ["Ali, Niklas"] },
          { week: 7, courtDuty: ["Simon", "Max", "Dima"], cashDuty: ["Daniel", "Stephan"] },
          { week: 8, courtDuty: ["Oleksandr", "Volodymyr", "Jonathan"], cashDuty: ["Kieran", "Marcel"]}
      ],
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  background-color: #f4f4f9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  color: #34495e;
  margin-top: 20px;
  font-size: 2.5em;
}

.container {
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 800px;
  width: 100%;
  margin-top: 20px;
}
</style>
